
import Quill from "@node_modules/quill/dist/quill.js";

import 'fullcalendar/main.js?ap=window.FullCalendar%20=%20FullCalendar';
import "@node_modules/fullcalendar/locales-all.min.js";


tinyMCE.baseURL = "/build/tinymce";

import "@node_modules/tinymce/tinymce.min.js";
import "@node_modules/tinymce/themes/silver/theme.js";
import "@node_modules/tinymce/themes/mobile/theme.js";
import "@node_modules/tinymce/icons/default/icons.js";


window.Quill = Quill;

//Optional dependencies

// import "@node_modules/@shopify/draggable/lib/draggable.bundle.js";
// import "@node_modules/@shopify/draggable/lib/draggable.bundle.legacy.js";
// import "@node_modules/@shopify/draggable/lib/draggable.js";
// import "@node_modules/@shopify/draggable/lib/sortable.js";
// import "@node_modules/@shopify/draggable/lib/droppable.js";
// import "@node_modules/@shopify/draggable/lib/swappable.js";
// import "@node_modules/@shopify/draggable/lib/plugins.js";
// import "@node_modules/@shopify/draggable/lib/plugins/collidable.js";
// import "@node_modules/@shopify/draggable/lib/plugins/resize-mirror.js";
// import "@node_modules/@shopify/draggable/lib/plugins/snappable.js";
// import "@node_modules/@shopify/draggable/lib/plugins/swap-animation.js";


// import "@node_modules/@ckeditor/ckeditor5-build-classic/build/ckeditor.js";
// import "@node_modules/@ckeditor/ckeditor5-build-inline/build/ckeditor.js";
// import "@node_modules/@ckeditor/ckeditor5-build-balloon/build/ckeditor.js";
// import "@node_modules/@ckeditor/ckeditor5-build-balloon-block/build/ckeditor.js";
// import "@node_modules/@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor.js";

/**
 *      Install following dependency
        "@ckeditor/ckeditor5-alignment": "38.1.1",
        "@ckeditor/ckeditor5-build-balloon": "38.1.1",
        "@ckeditor/ckeditor5-build-balloon-block": "38.1.1",
        "@ckeditor/ckeditor5-build-classic": "38.1.1",
        "@ckeditor/ckeditor5-build-decoupled-document": "38.1.1",
        "@ckeditor/ckeditor5-build-inline": "38.1.1",

 */

// import "@node_modules/cropperjs/dist/cropper.js";

// import "@node_modules/flot/dist/es5/jquery.flot.js";
// import "@node_modules/flot/source/jquery.flot.resize.js";
// import "@node_modules/flot/source/jquery.flot.categories.js";
// import "@node_modules/flot/source/jquery.flot.pie.js";
// import "@node_modules/flot/source/jquery.flot.stack.js";
// import "@node_modules/flot/source/jquery.flot.crosshair.js";
// import "@node_modules/flot/source/jquery.flot.axislabels.js";

// import "@node_modules/jquery.repeater/src/lib.js";
// import "@node_modules/jquery.repeater/src/jquery.input.js";
// import "@node_modules/jquery.repeater/src/repeater.js";
// import "@node_modules/bootstrap-cookie-alert/cookiealert.js";
// import "@node_modules/jstree/dist/jstree.js";


//IMAGE
// import "@theme/media/plugins/jstree/32px.png";
// import "@node_modules/jstree/dist/themes/default/throbber.gif";
